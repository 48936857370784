<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewLocation">New Sub Location</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
       :responsive="false">
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     
     
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                   <CDropdownItem :to="{name: 'Sub Location', params: {subLocationId: item.subLocationId, mode: 'view'}}">View</CDropdownItem> 
             
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteSubLocation(item.subLocationId)"
                    ></ConfirmationModal>

                </CDropdown> 

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
<CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  { key: "subLocationName",label: "Sub Location Name", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  
  { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Sub Locations",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getSubLocations() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/sub/locations/"+this.$route.params.locationId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    //  deleteSubLocation(subLocationId) {
    //   console.log("delete called with", this.subLocationId);
    //   this.apiGetSecure(
    //     process.env.VUE_APP_API_HOST +
    //       "/emapp/web/secure/delete/location/" +
    //      subLocationId
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.getSubLocations();
    //          this.$nextTick(() => {
    //          this.$refs.vuetable.refresh();
    //        });
    //        window.location.reload();
    //     });
    // },
     deleteSubLocation(subLocationId) {
      console.log("delete called with", subLocationId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/subLocation/" +
         subLocationId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getSubLocations();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
           window.location.reload();
        });
    },
   
    cancel() {
 
             this.$router.push({ name: "Locations" });
    },
    navToNewLocation(){
       this.$router.push({ name: "Sub Location" ,params: {locationId:this.$route.params.locationId }});
     }
  },
  mounted() {
    this.getSubLocations();
  },
};
</script>